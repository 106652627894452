import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useEffect, useState } from 'react';

import AirplaneGoing from '../../../assets/icons/AirplaneGoing.svg';
import AirplaneReturn from '../../../assets/icons/AirplaneReturn.svg';

import * as S from './styles';

import Separator from '../../Separator';
import getCompanyLogos from '../../../mock/companyLogos';
import { useGlobal } from '../../../contexts/globalContext';
import getCompanyName from '../../../mock/companyNames';
import Alert from '@material-ui/lab/Alert';

const FlightSideSumary = ({
	goingFlight,
	returnFlight,
	recommendation,
	onSubmit,
	sending,
	ticket,
	taxaEmbarque,
	total,
	userPoints,
	totalApagar,
	minimumPointsToBuy,
	cashPrice,
	pointsPrice,
	pointsTotalValue,
	cashTotalValue,
	discountPoints,
	discountCash,
	campaignDiscountPoints,
	campaignDiscountCash,
	discountPercentage,
}) => {
	const { signed, user } = useGlobal();
	const [coinAlias, setCoinAlias] = useState('Pontos');
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || 'Pontos');
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [
		user?.campaign?.campaignConfig?.coinAlias,
		user?.campaign?.campaignConfig?.shortenCoinAlias,
	]);

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
					<img
						className='airplane-badge'
						src={type === 'IDA' ? AirplaneGoing : AirplaneReturn}
						alt='icon'
					/>{' '}
				</div>
				<span className='date-header'>
					{date !== undefined &&
						date !== 'Invalid Date' &&
						format(parseISO(date), 'EEEE, dd MMM yyyy', {
							locale: pt,
						}).replace('-feira', '')}
				</span>
			</S.SideHeader>
		);
	};

	const Connection = ({
		img,
		company,
		connectionCode,
		departureHour,
		departureAirport,
		arrivalHour,
		arrivalAirport,
		connectionDiff,
		...props
	}) => {
		return (
			<S.Connection>
				<div className='company'>
					{img && <img src={img} alt='company' />}
					<div>
						{img && <span className='name'>{company}</span>}
						<span className='connection'>{`${
							!img ? 'Conexão ' : ''
						}${connectionCode}`}</span>
					</div>
				</div>
				<div>
					<div>
						{departureHour !== undefined &&
							departureHour !== 'Invalid Date' &&
							format(parseISO(departureHour), 'HH:mm')}
						{' > '}
						{arrivalHour !== undefined &&
							arrivalHour !== 'Invalid Date' &&
							format(parseISO(arrivalHour), 'HH:mm')}
						<span className='plus-day'>
							{connectionDiff > 0 ? `+${connectionDiff}` : ''}
						</span>
					</div>
					<div className='airport'>
						<span>{departureAirport}</span>
						<span>{arrivalAirport}</span>
					</div>
				</div>
			</S.Connection>
		);
	};

	const diffDaysGoing = differenceInCalendarDays(
		new Date(goingFlight?.arrivaldatetime),
		new Date(goingFlight?.departuredatetime)
	);

	const diffDaysReturn = differenceInCalendarDays(
		new Date(returnFlight?.arrivaldatetime),
		new Date(returnFlight?.departuredatetime)
	);

	return (
		<div>
			<Header className='first' type='IDA' date={goingFlight?.departuredatetime} />
			<S.BlankCardOnly>
				{goingFlight?.flights?.map((goingF, index) => {
					return (
						<>
							{index === 0 ? (
								<Connection
									img={getCompanyLogos(goingFlight?.validatingcarrier)}
									company={getCompanyName(goingFlight?.validatingcarrier)}
									departureHour={goingF?.departuredatetime}
									departureAirport={goingF?.departurestation}
									arrivalHour={goingF?.arrivaldatetime}
									arrivalAirport={goingF?.arrivalstation}
									connectionCode={`${goingFlight?.validatingcarrier}${goingF?.flightnumber}`}
									connectionDiff={
										index === goingFlight.flights.length - 1 ? diffDaysGoing : 0
									}
								/>
							) : (
								<Connection
									company={getCompanyName(goingFlight?.validatingcarrier)}
									departureHour={goingF?.departuredatetime}
									departureAirport={goingF?.departurestation}
									arrivalHour={goingF?.arrivaldatetime}
									arrivalAirport={goingF?.arrivalstation}
									connectionCode={`${goingFlight?.validatingcarrier}${goingF?.flightnumber}`}
									connectionDiff={
										index === goingFlight.flights.length - 1 ? diffDaysGoing : 0
									}
								/>
							)}
							{goingFlight?.flights?.length > 1 &&
								index + 1 !== goingFlight?.flights?.length && (
									<Separator className='dashed' margin={15} />
								)}
						</>
					);
				})}
			</S.BlankCardOnly>
			{returnFlight && (
				<>
					<Header type='VOLTA' date={returnFlight?.departuredatetime} />
					<S.BlankCardOnly>
						{' '}
						{returnFlight?.flights?.map((returnF, index) => {
							return (
								<>
									{index === 0 ? (
										<Connection
											img={getCompanyLogos(returnFlight?.validatingcarrier)}
											company={getCompanyName(returnFlight?.validatingcarrier)}
											departureHour={returnF?.departuredatetime}
											departureAirport={returnF?.departurestation}
											arrivalHour={returnF?.arrivaldatetime}
											arrivalAirport={returnF?.arrivalstation}
											connectionCode={`${returnFlight?.validatingcarrier}${returnF?.flightnumber}`}
											connectionDiff={
												index === returnFlight.flights.length - 1 ? diffDaysReturn : 0
											}
										/>
									) : (
										<Connection
											company={getCompanyName(returnFlight?.validatingcarrier)}
											departureHour={returnF?.departuredatetime}
											departureAirport={returnF?.departurestation}
											arrivalHour={returnF?.arrivaldatetime}
											arrivalAirport={returnF?.arrivalstation}
											connectionCode={`${returnFlight?.validatingcarrier}${returnF?.flightnumber}`}
											connectionDiff={
												index === returnFlight.flights.length - 1 ? diffDaysReturn : 0
											}
										/>
									)}
									{returnFlight?.flights?.length > 1 &&
										index + 1 !== returnFlight?.flights?.length && (
											<Separator className='dashed' margin={15} />
										)}
								</>
							);
						})}
					</S.BlankCardOnly>
				</>
			)}
			<Separator className='solid' />
			<S.BlankCardOnly>
				<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
				<S.Total>
					<span>Ticket</span>
					<span className='bold'>
						{signed && !user?.guest && !user?.campaign?.disablePointActivity
							? shortenCoinAlias
							: 'R$'}{' '}
						{new Intl.NumberFormat('pt-BR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(ticket)}
					</span>
				</S.Total>
				<Separator className='solid thin' margin={15} />
				<S.Total>
					<span>Taxa de Embarque</span>
					<span className='bold'>
						{signed && !user?.guest && !user?.campaign?.disablePointActivity
							? shortenCoinAlias
							: 'R$'}{' '}
						{new Intl.NumberFormat('pt-BR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(taxaEmbarque)}
					</span>
				</S.Total>
				<Separator className='solid' margin={15} />
				<S.Total className='wrap'>
					{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
						<div>
							<span className='bold total'>Total em {coinAlias}</span>
							<span className='bold total'>
								{signed ? shortenCoinAlias : 'R$'} {total}
							</span>
						</div>
					)}
					{/* <div>
            <span className='bold'>Meus Pontos</span>
            <span className='bold'>{userPoints.toLocaleString('pt-BR')}</span>
          </div> */}
				</S.Total>
				{discountPoints + campaignDiscountPoints > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em {coinAlias}</span>
						<span className='bold total'>
							{shortenCoinAlias} {discountPoints + campaignDiscountPoints}
						</span>
					</S.Total>
				)}
				{discountCash + campaignDiscountCash > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em R$</span>
						<span className='bold total'>
							{(discountCash + campaignDiscountCash).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
					</S.Total>
				)}
				{discountPercentage > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado</span>
						<span className='bold total'>{discountPercentage}%</span>
					</S.Total>
				)}
				{user?.campaign?.acceptCashPayment && (
					<S.Total>
						<span className='bold total'>Total em R$</span>
						<span className='bold total'>
							{new Intl.NumberFormat('pt-BR', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(totalApagar)}
						</span>
					</S.Total>
				)}

				{/* <S.Subtext>
          <p>
            * Tarifas adicionais da companhia aérea e do cartão de crédito podem
            ser aplicadas na emissão da passagem. Veja a fatura final
          </p>
        </S.Subtext> */}
				{signed && userPoints < minimumPointsToBuy && (
					<S.AlertStyle>
						<Alert variant='filled' severity='warning'>
							<span className='text'>
								Você deve possuir ao menos <br />
								<span className='value'>
									{minimumPointsToBuy} {coinAlias}
								</span>{' '}
								para realizar esta compra!
							</span>
						</Alert>
					</S.AlertStyle>
				)}
			</S.BlankCardOnly>
		</div>
	);
};

export default FlightSideSumary;
