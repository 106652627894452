/* eslint-disable array-callback-return */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import * as S from './styles';
import CheckboxFilter from '../../CheckboxFilter';
import {
  Slider,
  FormControlLabel,
  Switch,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useGlobal } from '../../../contexts/globalContext';
import getCompanyName from '../../../mock/companyNames';
import { addFeeByDestinoFerias } from '../../../utils/addFeeByDestinoFerias';

const FlightFilter = ({
  changeFilter,
  searchState,
  results,
  loading,
  error,
  buscaAtual,
}) => {
  const { user, signed } = useGlobal();

  const { recommendations, airports } = results;

  const [state, setState] = useState({
    flightNumber: '',
    sameAirport: false,
    cabin: [],
    horarioIda: {
      inicio: '00:00',
      fim: '23:59',
    },
    horarioVolta: {
      inicio: '00:00',
      fim: '23:59',
    },
    flightDuration: {
      inicio: '00:00',
      fim: '23:59',
    },
    connectionAirports: [],
    cias: [],
  });

  const [goingHour, setGoingHour] = React.useState([0, 48]);
  const [returnHour, setReturnHour] = React.useState([0, 48]);
  const [flightDuration, setFlightDuration] = React.useState([0, 250]);
  const [flightStops, setFlightStops] = React.useState([]);
  const [cabinClasses, setCabinClasses] = React.useState([]);
  const [connections, setConnections] = React.useState([]);
  const [airlines, setAirlines] = React.useState([]);

  const getValuesByUser = (val) => {
    let value = signed
      ? val * user.catalog.catalogCurrencyConfig.conversionRate
      : val;

    if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

    return signed
      ? Math.ceil(Math.round(value))
      : value.toLocaleString('pt-BR');
  };

  const onChangeFilters = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const changeCheckbox = (obj) => {
    setState({ ...state, [obj.name]: obj.checked });
  };

  const changeCheckboxCabin = (obj) => {
    let cabin = state.cabin;
    if (obj.checked) {
      cabin.push(obj.label);
    } else {
      cabin = cabin.filter((c) => c !== obj.label);
    }
    setState({ ...state, cabin });
  };

  const changeCheckboxConnectionAirports = (obj) => {
    let connectionAirports = state.connectionAirports;
    if (obj.checked) {
      connectionAirports.push(obj.name);
    } else {
      connectionAirports = connectionAirports.filter((c) => c !== obj.name);
    }
    setState({ ...state, connectionAirports });
  };

  const changeCheckboxCompanies = (obj) => {
    let cias = state.cias;
    if (obj.checked) {
      cias.push(obj.name);
    } else {
      cias = cias.filter((c) => c !== obj.name);
    }
    setState({ ...state, cias });
  };

  useEffect(() => {
    changeFilter(state);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState]);

  useEffect(() => {
    let flightStopOptions = [];
    let cabinClassesOptions = [];
    let connectionsOptions = [];
    let airlinesOptions = [];

    recommendations?.map((rec) => {
      const companyName = getCompanyName(rec.validatingcarrier);
      if (!airlinesOptions.find((x) => x.name === rec.sourcecode)) {
        airlinesOptions.push({
          name: rec.sourcecode,
          label: companyName,
          value: getValuesByUser(rec.totalfare.totalfare),
          disabled: false,
        });
      }

      rec.journeys?.map((journey) => {
        journey.segments?.map((segment) => {
          if (
            segment.flights.length === 1 &&
            !flightStopOptions.find((x) => x.name === 'stopsDirect')
          ) {
            flightStopOptions.push({
              name: 'stopsDirect',
              label: 'Direto',
              value: getValuesByUser(rec.totalfare.totalfare),
              disabled: false,
            });
          } else if (
            segment.flights.length === 2 &&
            !flightStopOptions.find((x) => x.name === 'stopsOneStop')
          ) {
            flightStopOptions.push({
              name: 'stopsOneStop',
              label: '1 parada',
              value: getValuesByUser(rec.totalfare.totalfare),
              disabled: false,
            });
          } else if (
            segment.flights.length > 2 &&
            !flightStopOptions.find((x) => x.name === 'stopsOneMoreStop')
          ) {
            flightStopOptions.push({
              name: 'stopsOneMoreStop',
              label: '+1 parada',
              value: getValuesByUser(rec.totalfare.totalfare),
              disabled: false,
            });
          }

          segment.flights?.map((flight) => {
            if (
              !cabinClassesOptions.find(
                (x) => x.name === flight.fareoption.cabin
              )
            ) {
              cabinClassesOptions.push({
                name: flight.fareoption.cabin,
                label: flight.fareoption.cabin,
                value: getValuesByUser(rec.totalfare.totalfare),
                disabled: false,
              });
            }

            if (
              flight.departurestation !== searchState.origem.iata &&
              flight.departurestation !== searchState.destino.iata
            ) {
              if (
                !connectionsOptions.find(
                  (x) => x.name === flight.departurestation
                )
              ) {
                const airpt = airports.find(
                  (air) => air.code === flight.departurestation
                );
                connectionsOptions.push({
                  name: airpt.code,
                  label: `${airpt.code} - ${airpt.cityname}`,
                  value: getValuesByUser(rec.totalfare.totalfare),
                  disabled: false,
                });
              }
            }
          });
        });
      });
    });
    setFlightStops(flightStopOptions);
    setCabinClasses(cabinClassesOptions);
    setConnections(connectionsOptions);
    setAirlines(airlinesOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airports,
    recommendations,
    searchState.destino.iata,
    searchState.origem.iata,
  ]);

  useEffect(() => {
    setState({
      ...state,
      horarioIda: {
        inicio: hoursArray[goingHour[0]],
        fim: hoursArray[goingHour[1]],
      },
      horarioVolta: {
        inicio: hoursArray[returnHour[0]],
        fim: hoursArray[returnHour[1]],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goingHour, returnHour]);

  useEffect(() => {
    setState({
      ...state,
      flightDuration: {
        inicio: flightDuration[0],
        fim: flightDuration[1],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightDuration]);

  return !recommendations ? (
    loading && <Skeleton variant='rect' width={'100%'} height={1000} />
  ) : (
    <div>
      {!error && (
        <S.Wrapper>
          {recommendations?.length > 0 && (
            <div className='filter-group'>
              <FormControl variant='outlined' className='form-control'>
                <OutlinedInput
                  id='outlined-input-iconed'
                  className='outlined-input-iconed'
                  placeholder='Voo#'
                  type='text'
                  name='flightNumber'
                  onChange={(e) => onChangeFilters(e)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton color='default'>
                        <Search size={24} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          )}

          <div className='filter-group'>
            {flightStops.length > 0 && <S.FilterTitle>Paradas</S.FilterTitle>}
            {flightStops.map((cbx, index) => (
              <CheckboxFilter
                key={index}
                label={cbx.label}
                name={cbx.name}
                disabled={cbx.disabled}
                valueLabel={cbx.value}
                onChange={(obj) => changeCheckbox(obj)}
              />
            ))}
          </div>
          <div className='filter-group'>
            {cabinClasses.length > 0 && (
              <S.FilterTitle>Classes de cabine</S.FilterTitle>
            )}
            {cabinClasses.map((cbx, index) => (
              <CheckboxFilter
                key={index}
                label={cbx.label}
                disabled={cbx.disabled}
                name={cbx.name}
                valueLabel={cbx.value}
                onChange={(obj) => changeCheckboxCabin(obj)}
              />
            ))}
          </div>
          {recommendations.length ? (
            searchState.origem.city && (
              <div className='filter-group'>
                <S.FilterTitle>{`Horário de Ida ${
                  searchState && '- ' + searchState.origem.city
                }`}</S.FilterTitle>
                <S.ThinText>
                  {`${hoursArray[goingHour[0]]} - ${hoursArray[goingHour[1]]}`}
                </S.ThinText>
                <Slider
                  value={goingHour}
                  onChange={(event, newValue) => setGoingHour(newValue)}
                  valueLabelDisplay='off'
                  aria-labelledby='range-slider'
                  min={0}
                  max={48}
                />
              </div>
            )
          ) : (
            <> </>
          )}
          {searchState.idaVolta &&
            searchState.destino.city &&
            recommendations?.length > 0 && (
              <div className='filter-group'>
                <S.FilterTitle>{`Horário de Volta ${
                  searchState && '- ' + searchState.destino.city
                }`}</S.FilterTitle>
                <S.ThinText>
                  {`${hoursArray[returnHour[0]]} - ${
                    hoursArray[returnHour[1]]
                  }`}
                </S.ThinText>
                <Slider
                  value={returnHour}
                  onChange={(event, newValue) => setReturnHour(newValue)}
                  valueLabelDisplay='off'
                  aria-labelledby='range-slider'
                  min={0}
                  max={48}
                />
              </div>
            )}
          {recommendations?.length > 0 && (
            <div className='filter-group'>
              <S.FilterTitle>Duração de Voo</S.FilterTitle>
              <S.ThinText>
                {`${flightDuration[0]}h - ${flightDuration[1]}h`}
              </S.ThinText>

              <Slider
                value={flightDuration}
                onChange={(event, newValue) => setFlightDuration(newValue)}
                valueLabelDisplay='off'
                aria-labelledby='range-slider'
                min={0}
                max={250}
              />
            </div>
          )}
          {searchState.idavolta && recommendations?.length > 0 && (
            <div className='filter-group'>
              <S.FilterTitle>Aéroportos</S.FilterTitle>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.sameAirport}
                    onChange={() =>
                      setState({ ...state, sameAirport: !state.sameAirport })
                    }
                  />
                }
                label={<S.ThinText>Ida e Volta no mesmo aeroporto</S.ThinText>}
              />
            </div>
          )}
          {connections.length > 0 && (
            <div className='filter-group'>
              <S.FilterTitle>Aeroportos de conexão</S.FilterTitle>
              {connections.map((cbx, index) => (
                <CheckboxFilter
                  key={index}
                  label={cbx.label}
                  disabled={cbx.disabled}
                  name={cbx.name}
                  valueLabel={cbx.value}
                  onChange={(obj) => changeCheckboxConnectionAirports(obj)}
                />
              ))}
            </div>
          )}
          {airlines.length > 0 && (
            <div className='filter-group'>
              <S.FilterTitle>Cias Aéreas</S.FilterTitle>
              {airlines.map((cbx, index) => (
                <CheckboxFilter
                  key={index}
                  label={cbx.label}
                  disabled={cbx.disabled}
                  name={cbx.name}
                  valueLabel={cbx.value}
                  onChange={(obj) => changeCheckboxCompanies(obj)}
                />
              ))}
            </div>
          )}
        </S.Wrapper>
      )}
    </div>
  );
};

export default FlightFilter;

const hoursArray = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
];
