import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../contexts/globalContext";
import isEqual from "lodash/isEqual";

import * as S from "./styles";
import EntertainmentCard from "./EntertainmentCard";
import EntertainmentLoader from "./EntertainmentLoader";
import { getSessionStorage } from "../../../utils/getSessionStorage";

const EntertainmentList = ({ filters, loading, error, searchState }) => {
    const { setLoading } = useGlobal();
    const [newPassengersRate, setNewPassengersRate] = useState([]);
    const [currentEntertainmentSearch, setCurrentEntertainmentSearch] =
        useState(null);

    const applyFilters = () => {
        if (!searchState || !searchState.activities) return [];

        return searchState.activities.filter((activity) => {
            const matchesLocation =
                filters?.locations.length === 0 ||
                filters?.locations.includes(activity.locationFull);

            const matchesCategory =
                filters?.category.length === 0 ||
                filters?.category.some((category) => {
                    const activityCategory = activity.activityId.startsWith("4")
                        ? "Transfer"
                        : activity.activityId.startsWith("5")
                        ? "Ingresso"
                        : "Passeio";

                    return category === activityCategory;
                });

            const matchesWeekDays =
                filters?.weekDays.length === 0 ||
                filters?.weekDays.some((day) => {
                    const days = [
                        "Domingo",
                        "Segunda-feira",
                        "Terça-feira",
                        "Quarta-feira",
                        "Quinta-feira",
                        "Sexta-feira",
                        "Sábado",
                    ];

                    const selectedDaysIndexes = filters.weekDays.map(
                        (selectedDay) => days.indexOf(selectedDay)
                    );

                    const activityDays = activity.weekDaysList
                        .split(";")
                        .filter((day) => day !== "")
                        .map((day) => parseInt(day, 10));

                    return selectedDaysIndexes.some((selectedDayIndex) =>
                        activityDays.includes(selectedDayIndex)
                    );
                });

            return matchesLocation && matchesCategory && matchesWeekDays;
        });
    };

    const filteredActivities = applyFilters();

    useEffect(() => {
        const storedEntertainmentSearch = getSessionStorage(
            "entertainmentSearch"
        );
        if (!isEqual(currentEntertainmentSearch, storedEntertainmentSearch)) {
            setCurrentEntertainmentSearch(storedEntertainmentSearch);
            sessionStorage.setItem(
                "entertainmentSearch",
                JSON.stringify({
                    ...storedEntertainmentSearch,
                    newPassengersRate: newPassengersRate,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState, newPassengersRate]);

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            const allTransformed = [];

            filteredActivities.forEach((activity) => {
                let passengersRate = null;

                if (activity?.ticketPassengerRate) {
                    passengersRate = activity.ticketPassengerRate;
                } else if (activity?.tourPassengerRate) {
                    passengersRate = activity.tourPassengerRate;
                } else if (activity?.transferPassengerRate) {
                    passengersRate = activity.transferPassengerRate;
                }

                if (passengersRate) {
                    const transformed = passengersRate.map((rate) => ({
                        activityId: activity.activityId,
                        endAge: rate.endAge,
                        name: rate.name,
                        priceTotalOriginal: rate.price.priceTotalOriginal,
                    }));

                    allTransformed.push(...transformed);
                }
            });

            setNewPassengersRate((prevRates) => {
                return isEqual(prevRates, allTransformed)
                    ? prevRates
                    : allTransformed;
            });
        }
    }, [filteredActivities]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setLoading(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return loading ? (
        <Grid container>
            <Grid item sm={12} md={12} lg={12}>
                <S.Container>
                    <EntertainmentLoader />
                </S.Container>
            </Grid>
        </Grid>
    ) : (
        <>
            <S.CounterText>
                <span>{filteredActivities.length} Resultados encontrados</span>
            </S.CounterText>
            {filteredActivities.length > 0 &&
                filteredActivities.map((activity) => (
                    <S.Wrapper
                        className="wrapperList"
                        key={activity.activityId}
                    >
                        <EntertainmentCard
                            activity={activity}
                            newSearchState={currentEntertainmentSearch}
                        />
                    </S.Wrapper>
                ))}
        </>
    );
};

export default EntertainmentList;
