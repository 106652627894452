import * as S from "../styles";
import { useState, useEffect, useRef } from "react";
import { pt } from "react-date-range/dist/locale";
import { Container, Grid } from "@material-ui/core";
import { addDays, addMonths, format, isAfter, parse } from "date-fns";
import { useHistory } from "react-router-dom";

import CarouselComponent from "../../../components/Carousel";
import ThemeButton from "../../../components/ThemeButton";
import { getSessionStorage } from "../../../utils/getSessionStorage";
import Duration from "../../../assets/icons/Duration.svg";
import Cancelation from "../../../assets/icons/Cancelation.svg";
import Confirmation from "../../../assets/icons/Confirmation.svg";
import Availability from "../../../assets/icons/Availability.svg";
import Star from "../../../assets/icons/Star.svg";
import Check from "../../../assets/icons/Check.svg";
import Positive from "../../../assets/icons/Positive.svg";
import Negative from "../../../assets/icons/Negative.svg";
import Information from "../../../assets/icons/Information.svg";
import EntertainmentRepository from "../../../repositories/entertainmentRepository";
import EntertainmentLoader from "../../SearchContent/EntertainmentList/EntertainmentLoader";
import { useGlobal } from "../../../contexts/globalContext";
import PolicyIcon from "../../../assets/icons/PolicyIcon";
import LuggageIcon from "../../../assets/icons/LuggageIcon";
import CancellationPolicy from "../CancellationPolicyModal";
import ControllableCounter from "./../../ControllableCounter";
import InputCalendar from "../../InputDate";
import SvgContentCopyBlack24Dp from "./../../../assets/icons/ContentCopyBlack24Dp";
import CalendarPrimary from "../../../assets/icons/CalendarPrimary.svg";

const TransferActivity = ({ buscaAtual }) => {
    const { showAlert /** , signed **/ } = useGlobal();
    const [activity, setActivity] = useState({});
    const [date, setDate] = useState(null);
    const [availabilityData, setAvailabilityData] = useState({});
    let history = useHistory();
    const availabilityBoxRef = useRef(null);
    const [selectedActivity, setSelectedActivity] = useState({});
    const [transferData, setTransferData] = useState({});
    const [showCancellationPolicy, setShowCancellationPolicy] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [isTimeSelected, setIsTimeSelected] = useState(false);
    const [send, setSend] = useState(false);
    const [newPassengerRates, setNewPassengerRates] = useState({});
    const [loading, setLoading] = useState(null);

    const initialState = {
        data: "",
    };

    const [state, setState] = useState(initialState);

    const entertainmentRepo = new EntertainmentRepository();

    const [selectedDateFormatted, setSelectedDateFormatted] = useState(null);

    const [passengerCount, setPassengerCount] = useState({
        Adulto: 1,
        Crianca: 0,
    });

    const updatePassengerCount = (type, value) => {
        setPassengerCount((prev) => ({
            ...prev,
            [type]: Math.max(0, (prev[type] || 0) + value),
        }));
    };

    const handleCheckAvailability = async () => {
        const requestFormattedDate = format(date, "yyyy-MM-dd");

        const passengerRate = [];

        selectedActivity.transferPassengerRate.forEach((rate) => {
            const quantity = passengerCount[rate.name] || 0;

            for (let i = 0; i < quantity; i++) {
                passengerRate.push({
                    endAge: rate.endAge,
                    quantity: 1,
                });
            }
        });

        if (passengerRate.length === 0) {
            console.error("Nenhum passageiro selecionado.");
            return;
        }

        const availabilityResponse = await entertainmentRepo.checkAvailability(
            selectedActivity.activityId,
            selectedActivity.refToken,
            passengerRate,
            requestFormattedDate
        );

        if (availabilityResponse) {
            setAvailabilityData(availabilityResponse[0]);
        } else {
            console.error("Nenhum dado de disponibilidade retornado");
        }
    };

    const isValidDate = (dateString) => {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        return regex.test(dateString);
    };

    const availableDatesFormatted = Array.isArray(
        selectedActivity?.avaiableDates
    )
        ? selectedActivity.avaiableDates
            .filter((date) => isValidDate(date))
            .map((date) => parse(date, "dd/MM/yyyy", new Date()))
        : [];

    const isDateAvailable = (date) => {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return false;
        }

        const formattedDate = format(date, "dd/MM/yyyy");

        return availableDatesFormatted.some((availableDate) => {
            return format(availableDate, "dd/MM/yyyy") === formattedDate;
        });
    };

    useEffect(() => {
        if (availabilityData && Object.keys(availabilityData).length > 0) {
            const extractTransferInfo = (availabilityData) => {
                const info = availabilityData.transfers[0];
                const bestPrice = availabilityData.bestPrice;
                const startingTimes = availabilityData.startingTimes;
                const cancellationRule =
                    availabilityData.transfers[0].datesRate[0]
                        .cancellationRules[0];
                const returnDate = state.data.dataIda;

                const formatDate = (dateString, formatString) =>
                    format(new Date(dateString), formatString);

                const calculateTotalPrice = () => {
                    let total = 0;

                    selectedActivity.transferPassengerRate.forEach((rate) => {
                        const passengerName = rate.name;
                        const passengerQuantity =
                            passengerCount[passengerName] || 0;

                        if (
                            rate.price.priceTotalOriginal > 0 &&
                            passengerQuantity > 0
                        ) {
                            total +=
                                rate.price.priceTotalOriginal *
                                passengerQuantity;
                        }
                    });

                    return total;
                };

                return {
                    transferIn: info.transferIn,
                    transferOut: info.transferOut,
                    type: info.transferType.type,
                    maxNumberBaggage: info.transferType.maxNumberBaggage,
                    maxNumberPassenger: info.transferType.maxNumberPassenger,
                    serviceDate: formatDate(
                        availabilityData.serviceDate,
                        "dd/MM/yyyy"
                    ),
                    passengerRate: newPassengerRates,
                    startingTimes: startingTimes,
                    selectedTime: selectedTime,
                    returnDate: returnDate,
                    currencySymbol: bestPrice.currency.symbol,
                    totalPrice: calculateTotalPrice(),
                    name: info.name,
                    cancellationStartDate: {
                        date: formatDate(
                            cancellationRule.startDate,
                            "dd/MM/yyyy"
                        ),
                        time: formatDate(cancellationRule.startDate, "HH:mm"),
                    },
                    cancellationEndDate: {
                        date: formatDate(
                            cancellationRule.endDate,
                            "dd/MM/yyyy"
                        ),
                        time: formatDate(cancellationRule.endDate, "HH:mm"),
                    },
                    cancellationPrice: cancellationRule.price,
                };
            };

            setTransferData(extractTransferInfo(availabilityData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilityData, state]);

    useEffect(() => {
        const extractPassengersRate = () => {
            if (!Array.isArray(activity?.passengerRate)) {
                return [];
            }

            const passengersData = activity.passengerRate.map((rate) => {
                const quantity = passengerCount[rate.name] || 0;
                const totalPrice =
                    rate.price.priceTotalOriginal > 0
                        ? rate.price.priceTotalOriginal * quantity
                        : 0;

                return {
                    name: rate.name,
                    quantity: quantity,
                    price: totalPrice,
                };
            });

            return passengersData;
        };

        const passengersRate = extractPassengersRate();

        setNewPassengerRates(passengersRate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passengerCount]);

    const handleSubmit = () => {
        sessionStorage.setItem(
            "@EntertainmentSelected",
            JSON.stringify({
                data: transferData,
                availabilityData,
                passageiros: passengerCount,
            })
        );
        sessionStorage.setItem("buscaAtual", "Entretenimento");
        history.push("/busca/resumo");
    };

    useEffect(() => {
        if (activity.passengerRate) {
            const initialCounts = activity?.passengerRate.reduce(
                (acc, rate) => {
                    acc[rate.name] = rate.name === "Adulto" ? 1 : 0;
                    return acc;
                },
                {}
            );
            setPassengerCount(initialCounts);
        }
    }, [activity]);

    useEffect(() => {
        setSelectedActivity(activity.activity);
    }, [activity]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setActivity(getSessionStorage("@EntertainmentSelected"));
    }, []);

    useEffect(() => {
        if (activity) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (transferData && Object.keys(transferData).length > 0) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferData]);

    return (
        <>
            <S.Wrapper>
                <Container>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <S.Title>{selectedActivity?.name}</S.Title>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <CarouselComponent
                                type={"entertainment-card"}
                                buscaAtual={buscaAtual}
                                images={
                                    selectedActivity?.transfer &&
                                        Array.isArray(
                                            selectedActivity?.transfer.images
                                        ) &&
                                        selectedActivity?.transfer.images.length > 0
                                        ? selectedActivity?.transfer.images.map(
                                            (image) => image.url
                                        )
                                        : [selectedActivity?.pictureUrl] || []
                                }
                            />
                        </Grid>
                        <Grid container>
                            <S.GridFlexible
                                className="spacing top"
                                item
                                xs={12}
                                lg={8}
                            >
                                <S.AboutContainer className="about">
                                    <span>Sobre a experiência</span>
                                    <p className="text">
                                        {selectedActivity?.description}
                                    </p>
                                    <div className="tags">
                                        <div className="left">
                                            {selectedActivity?.duration > 0 && (
                                                <div>
                                                    <img
                                                        src={Duration}
                                                        alt="duração da experiência"
                                                    />
                                                    <div className="info">
                                                        <span className="top">
                                                            Duração da
                                                            experiência
                                                        </span>
                                                        <span className="bottom">
                                                            {
                                                                selectedActivity?.duration
                                                            }
                                                            h
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <img
                                                    src={Confirmation}
                                                    alt="confirmação"
                                                />
                                                <div className="info">
                                                    <span className="top">
                                                        Confirmação da
                                                        experiência
                                                    </span>
                                                    <span className="bottom">
                                                        Imediata
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div>
                                                <img
                                                    src={Cancelation}
                                                    alt="cancelamento"
                                                />
                                                <div className="info">
                                                    <span className="top">
                                                        Cancelamento{" "}
                                                    </span>
                                                    <span className="bottom">
                                                        Grátis até{" "}
                                                        {
                                                            selectedActivity?.daysBeforeCancellationPolicy
                                                        }{" "}
                                                        dias antes
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    src={Availability}
                                                    alt="disponibilidade"
                                                />
                                                <div className="info">
                                                    <span className="top">
                                                        Disponibilidade
                                                    </span>
                                                    <span className="bottom">
                                                        {
                                                            selectedActivity?.weekDays
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </S.AboutContainer>
                                <S.AboutContainer className="highlights">
                                    <div className="title">
                                        <span>
                                            Destaques da experiência&nbsp;
                                        </span>
                                        <img src={Star} alt="estrela" />
                                    </div>
                                    <div className="highlights-grid">
                                        {selectedActivity?.transfer &&
                                            Array.isArray(
                                                selectedActivity?.transfer
                                                    .inclusions
                                            ) &&
                                            selectedActivity?.transfer.inclusions
                                                .filter(
                                                    (inclusion) =>
                                                        inclusion.type ===
                                                        "Destaques" &&
                                                        inclusion.id === 32
                                                )
                                                .map((inclusion, index) => (
                                                    <div
                                                        key={index}
                                                        className="highlight-item"
                                                    >
                                                        <img
                                                            src={Check}
                                                            alt="ícone check"
                                                        />
                                                        <p>
                                                            {
                                                                inclusion.description
                                                            }
                                                        </p>
                                                    </div>
                                                ))}
                                    </div>
                                </S.AboutContainer>
                                <S.AboutContainer className="included">
                                    <span className="title">
                                        O que inclui e não inclui
                                    </span>
                                    <div className="list-container">
                                        <div className="includes">
                                            {selectedActivity?.transfer &&
                                                Array.isArray(
                                                    selectedActivity?.transfer
                                                        .inclusions
                                                ) &&
                                                selectedActivity?.transfer.inclusions
                                                    .filter(
                                                        (inclusion) =>
                                                            inclusion.id === 33
                                                    )
                                                    .map((inclusion, index) => (
                                                        <div
                                                            key={index}
                                                            className="item"
                                                        >
                                                            <img
                                                                src={Positive}
                                                                alt="Incluído"
                                                            />
                                                            <span className="description">
                                                                &nbsp;
                                                                {
                                                                    inclusion.description
                                                                }
                                                            </span>
                                                        </div>
                                                    ))}
                                        </div>
                                        <div className="excludes">
                                            {selectedActivity?.transfer &&
                                                Array.isArray(
                                                    selectedActivity?.transfer
                                                        .inclusions
                                                ) &&
                                                selectedActivity?.transfer.inclusions
                                                    .filter(
                                                        (inclusion) =>
                                                            inclusion.id === 34
                                                    )
                                                    .map((inclusion, index) => (
                                                        <div
                                                            key={index}
                                                            className="item"
                                                        >
                                                            <img
                                                                src={Negative}
                                                                alt="Não Incluído"
                                                            />
                                                            <span className="description">
                                                                &nbsp;
                                                                {
                                                                    inclusion.description
                                                                }
                                                            </span>
                                                        </div>
                                                    ))}
                                        </div>
                                    </div>
                                </S.AboutContainer>
                                <S.AboutContainer className="box">
                                    <h2 className="title">
                                        Prepare-se para que a sua experiência
                                        possa ser{" "}
                                        <span className="highlight">
                                            INCRÍVEL!
                                        </span>
                                    </h2>
                                    <p className="description">
                                        Confira algumas informações e cuidados
                                        essenciais para que você tenha uma
                                        experiência única do começo ao fim:
                                    </p>
                                    <div className="information-list">
                                        {selectedActivity?.transfer &&
                                            Array.isArray(
                                                selectedActivity?.transfer
                                                    .inclusions
                                            ) &&
                                            selectedActivity?.transfer.inclusions
                                                .filter(
                                                    (inclusion) =>
                                                        inclusion.id === 35
                                                )
                                                .map((inclusion, index) => (
                                                    <div
                                                        key={index}
                                                        className="items"
                                                    >
                                                        <img
                                                            src={Information}
                                                            alt="Informação"
                                                        />
                                                        <span className="item">
                                                            &nbsp;
                                                            {
                                                                inclusion.description
                                                            }
                                                        </span>
                                                    </div>
                                                ))}
                                    </div>
                                </S.AboutContainer>
                            </S.GridFlexible>
                            <S.GridFlexible
                                className="spacing"
                                item
                                xs={12}
                                lg={4}
                            >
                                <div className="calendar">
                                    <S.Price>
                                        <div className="top">
                                            <span className="label1">
                                                A partir de:
                                            </span>
                                            <div>
                                                <span className="price">
                                                    {activity.passengerRate
                                                        ?.find(
                                                            (rate) =>
                                                                rate.name ===
                                                                "Adulto"
                                                        )
                                                        ?.price.priceTotalOriginal.toLocaleString(
                                                            "pt-br",
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        )}
                                                    {" pts"}
                                                </span>
                                                <span className="label2">
                                                    {" "}
                                                    / por serviço
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mid">
                                            <div className="separator">
                                                <div className="cancelation tooltip">
                                                    <span className="label3">
                                                        ver condições
                                                    </span>
                                                    <div className="tooltiptext">
                                                        <h3>
                                                            Preço válido para:
                                                        </h3>
                                                        <p>
                                                            {
                                                                selectedActivity
                                                                    ?.transfer
                                                                    ?.transferType
                                                                    ?.type
                                                            }{" "}
                                                            {selectedActivity
                                                                ?.transfer
                                                                ?.transferType
                                                                ?.maxNumberPassenger >
                                                                0
                                                                ? "- Até " +
                                                                selectedActivity
                                                                    ?.transfer
                                                                    ?.transferType
                                                                    ?.maxNumberPassenger +
                                                                " paxs"
                                                                : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </S.Price>
                                    <S.Quantity>
                                        <div className="quantity-top">
                                            <span className="label1">
                                                Escolha a quantidade de
                                                pessoas&nbsp;
                                            </span>
                                            <span className="label2">
                                                (Obrigatório)
                                            </span>
                                        </div>
                                        <div className="bottom">
                                            {activity.passengerRate?.map(
                                                (rate, index) => (
                                                    <div
                                                        key={index}
                                                        className="passenger-item"
                                                    >
                                                        <span className="label">
                                                            {rate.name}
                                                        </span>
                                                        <ControllableCounter
                                                            count={
                                                                passengerCount[
                                                                rate.name
                                                                ] || 0
                                                            }
                                                            onIncrement={() =>
                                                                updatePassengerCount(
                                                                    rate.name,
                                                                    1
                                                                )
                                                            }
                                                            onDecrement={() =>
                                                                updatePassengerCount(
                                                                    rate.name,
                                                                    -1
                                                                )
                                                            }
                                                            isAdult={
                                                                rate.name ===
                                                                "Adulto"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </S.Quantity>
                                    <S.DateCalendar
                                        onChange={(item) => {
                                            setDate(item);
                                            setSelectedDateFormatted(item);
                                        }}
                                        locale={pt}
                                        date={date}
                                        minDate={addDays(new Date(), 0)}
                                        maxDate={addMonths(new Date(), 12)}
                                        dayContentRenderer={(date) => {
                                            const isAvailable =
                                                isDateAvailable(date) &&
                                                isAfter(date, new Date());

                                            if (isAvailable) {
                                                return (
                                                    <div
                                                        style={{
                                                            opacity: 1,
                                                            pointerEvents:
                                                                "auto",
                                                            color: "#333",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        {date.getDate()}{" "}
                                                    </div>
                                                );
                                            }

                                            return (
                                                <div
                                                    style={{
                                                        opacity: 0.5,
                                                        pointerEvents: "none",
                                                        color: "#d3d3d3",
                                                        padding: "5px",
                                                    }}
                                                >
                                                    {date.getDate()}{" "}
                                                </div>
                                            );
                                        }}
                                        tileDisabled={({ date }) =>
                                            !isDateAvailable(date) ||
                                            !isAfter(date, new Date())
                                        }
                                    />
                                </div>
                                <ThemeButton
                                    onClick={() => {
                                        setLoading(true);

                                        if (!date) {
                                            setLoading(false);
                                            showAlert(
                                                "error",
                                                "Por favor, selecione uma data antes de verificar a disponibilidade."
                                            );
                                            return;
                                        }

                                        try {
                                            const selectedDate = new Date(
                                                selectedDateFormatted
                                            );
                                            if (isNaN(selectedDate.getTime())) {
                                                throw new Error(
                                                    "Data inválida selecionada"
                                                );
                                            }

                                            const isAvailable =
                                                isDateAvailable(date);

                                            if (isAvailable) {
                                                handleCheckAvailability();
                                                sessionStorage.setItem(
                                                    "@EntertainmentSelected",
                                                    JSON.stringify(
                                                        selectedActivity
                                                    )
                                                );
                                                availabilityBoxRef.current.scrollIntoView(
                                                    { behavior: "smooth" }
                                                );
                                            } else {
                                                setLoading(false);
                                                showAlert(
                                                    "error",
                                                    "Dia indisponível, por favor selecione um dos dias destacados no calendário."
                                                );
                                            }
                                        } catch (error) {
                                            setLoading(false);
                                            console.error(
                                                "Erro ao formatar data:",
                                                error.message
                                            );
                                            showAlert(
                                                "error",
                                                "Data inválida, por favor selecione uma data válida."
                                            );
                                        }
                                    }}
                                    className="choose-entertainment"
                                >
                                    Verificar disponibilidade
                                </ThemeButton>
                            </S.GridFlexible>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <S.AvailabilityBox ref={availabilityBoxRef}>
                                {loading ? (
                                    <Grid container>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <S.Container>
                                                <EntertainmentLoader />
                                            </S.Container>
                                        </Grid>
                                    </Grid>
                                ) : transferData &&
                                    Object.keys(transferData).length > 0 ? (
                                    <>
                                        <div className="tour-info">
                                            <div className="tour-type">
                                                <span className="tour-type-name">
                                                    {transferData.type ||
                                                        "Compartilhado"}
                                                </span>
                                            </div>

                                            <div className="return-info">
                                                {transferData.transferOut ===
                                                    true ? (
                                                    <>
                                                        <h3>
                                                            Selecione a data de
                                                            retorno
                                                        </h3>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                                <div className="dates">
                                                    <div className="date-arrival">
                                                        <span className="tour-date-label">
                                                            Data de chegada
                                                        </span>
                                                        <span className="tour-date-value">
                                                            {transferData.serviceDate
                                                                ? transferData.serviceDate
                                                                : "Data não disponível"}
                                                        </span>
                                                    </div>
                                                    {transferData.transferOut ===
                                                        true ? (
                                                        <>
                                                            <div className="date-return">
                                                                <span className="tour-date-label">
                                                                    Data de
                                                                    retorno
                                                                </span>
                                                                <InputCalendar
                                                                    icon={
                                                                        CalendarPrimary
                                                                    }
                                                                    reverseColor={
                                                                        SvgContentCopyBlack24Dp
                                                                    }
                                                                    value={
                                                                        state.data
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setState(
                                                                            {
                                                                                ...state,
                                                                                data: e,
                                                                            }
                                                                        )
                                                                    }
                                                                    error={
                                                                        send &&
                                                                        state.data ===
                                                                        initialState.data
                                                                    }
                                                                    helperText={
                                                                        send &&
                                                                            state.data ===
                                                                            initialState.data
                                                                            ? "Data não pode ser vazia"
                                                                            : ""
                                                                    }
                                                                    minStartDays={
                                                                        1
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                {transferData.maxNumberBaggage &&
                                                    transferData.maxNumberBaggage >
                                                    0 ? (
                                                    <div className="luggage-info">
                                                        <LuggageIcon />
                                                        <span>
                                                            {
                                                                transferData.maxNumberBaggage
                                                            }{" "}
                                                            {transferData.maxNumberBaggage !==
                                                                1
                                                                ? "malas"
                                                                : "mala"}{" "}
                                                            por pessoa
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {transferData.startingTimes &&
                                                    transferData.startingTimes
                                                        .length > 0 ? (
                                                    <div className="tour-times">
                                                        {transferData.startingTimes.map(
                                                            (time, index) => (
                                                                <button
                                                                    key={index}
                                                                    className={`tour-time-button ${selectedTime ===
                                                                        time
                                                                        ? "selected"
                                                                        : ""
                                                                        }`}
                                                                    onClick={() => {
                                                                        setSelectedTime(
                                                                            time
                                                                        );
                                                                        setIsTimeSelected(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    {time}
                                                                </button>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>

                                        <div className="price-and-cancellation">
                                            <div className="price-box">
                                                <div className="price-summary">
                                                    <div className="price-info">
                                                        <span className="price-label">
                                                            A partir de
                                                        </span>
                                                        <span className="price-value">
                                                            {transferData.totalPrice !==
                                                                undefined &&
                                                                transferData.totalPrice !==
                                                                null &&
                                                                !isNaN(
                                                                    transferData.totalPrice
                                                                )
                                                                ? (
                                                                    transferData.totalPrice.toLocaleString(
                                                                        "pt-br",
                                                                        {
                                                                            minimumFractionDigits: 0,
                                                                            maximumFractionDigits: 0,
                                                                        }
                                                                    )
                                                                ) +
                                                                " pts"
                                                                : "Preço não disponível"}
                                                        </span>
                                                    </div>
                                                    <div className="passenger-summary">
                                                        {newPassengerRates.map(
                                                            (
                                                                passenger,
                                                                index
                                                            ) => (
                                                                <span
                                                                    key={index}
                                                                >
                                                                    {
                                                                        passenger.quantity
                                                                    }
                                                                    x{" "}
                                                                    {
                                                                        passenger.name
                                                                    }{" "}
                                                                    {
                                                                        passenger.price.toLocaleString(
                                                                            "pt-br",
                                                                            {
                                                                                minimumFractionDigits: 0,
                                                                                maximumFractionDigits: 0,
                                                                            }
                                                                        )
                                                                    }{" "}
                                                                    pts
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <ThemeButton
                                                    className="select-button"
                                                    onClick={() => {
                                                        if (
                                                            transferData.startingTimes &&
                                                            transferData
                                                                .startingTimes
                                                                .length > 0 &&
                                                            !isTimeSelected
                                                        ) {
                                                            showAlert(
                                                                "error",
                                                                "Por favor, selecione um horário antes de prosseguir para o checkout."
                                                            );
                                                            return;
                                                        } else if (
                                                            transferData.transferOut ===
                                                            true &&
                                                            state.data === ""
                                                        ) {
                                                            showAlert(
                                                                "error",
                                                                "Por favor, selecione uma data de retorno antes de prosseguir para o checkout."
                                                            );
                                                            return;
                                                        } else {
                                                            handleSubmit();
                                                        }
                                                    }}
                                                >
                                                    Selecionar
                                                </ThemeButton>
                                            </div>

                                            <div
                                                className="cancellation-policy"
                                                onClick={() =>
                                                    setShowCancellationPolicy(
                                                        true
                                                    )
                                                }
                                            >
                                                <PolicyIcon className="policy-icon" />
                                                <span className="policy-label">
                                                    Política de cancelamento
                                                </span>
                                            </div>

                                            {showCancellationPolicy && (
                                                <CancellationPolicy
                                                    data={transferData}
                                                    onClose={() =>
                                                        setShowCancellationPolicy(
                                                            false
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <span className="no-availability">
                                        Antes de visualizar as opções desta
                                        experiência, verifique a disponibilidade
                                        dela clicando no botão acima.
                                    </span>
                                )}
                            </S.AvailabilityBox>
                        </Grid>
                    </Grid>
                </Container>
            </S.Wrapper>
        </>
    );
};
export default TransferActivity;
