import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useState, useEffect } from 'react';

import * as S from './styles';

import Separator from '../../Separator';
import { useGlobal } from '../../../contexts/globalContext';
import Alert from '@material-ui/lab/Alert';

const CarSideSummary = ({
	car,
	total,
	totalApagar,
	cashPrice,
	pointsPrice,
	pointsTotalValue,
	cashTotalValue,
	userPoints,
	minimumPointsToBuy,
	discountPoints,
	discountCash,
}) => {
	const { signed, user } = useGlobal();
	const [coinAlias, setCoinAlias] = useState('Pontos');
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || 'Pontos');
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [
		user?.campaign?.campaignConfig?.coinAlias,
		user?.campaign?.campaignConfig?.shortenCoinAlias,
	]);

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
				</div>
				<span className='date-header'>
					{date !== undefined &&
						date !== 'Invalid Date' &&
						format(parseISO(date), 'dd/MM/yyyy HH:mm', {
							locale: pt,
						})}
				</span>
			</S.SideHeader>
		);
	};

	return (
		<div>
			{car && (
				<>
					<Header className='first' type='Retirada' date={car.pickupDateTime} />
					<S.BlankCardOnly className='get-car-place'>
						<img
							className='company-logo'
							src={car.urlLogoCompany}
							alt='Locadora logotipo'
						/>
						<span className='car-type'>{car.categoryName}</span>
						<span className='car-model'>{car.model}</span>
					</S.BlankCardOnly>
					<Header type='Devolução' date={car.pickupDateTime} />
					<S.BlankCardOnly className='get-car-place'>
						<img
							className='company-logo'
							src={car.urlLogoCompany}
							alt='Locadora logotipo'
						/>
						<span className='car-type'>{car.category.replace('resCar', '')}</span>
						<span className='car-model'>{car.model}</span>
					</S.BlankCardOnly>

					<Separator className='solid thin' />
					<S.BlankCardOnly>
						<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
						<Separator className='solid thin' margin={15} />
						<Separator className='solid' margin={15} />
						<S.Total className='wrap'>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
								<div>
									<span className='bold total'>Total em {coinAlias}</span>
									<span className='bold total'>
										{signed ? shortenCoinAlias : 'R$'} {total}
									</span>
								</div>
							)}
							{/* <div>
            <span className='bold'>Meus Pontos</span>
            <span className='bold'>{userPoints.toLocaleString('pt-BR')}</span>
          </div> */}
						</S.Total>
						{discountPoints > 0 && (
							<S.Total>
								<span className='bold total'>Desconto aplicado em {coinAlias}</span>
								<span className='bold total'>
									{shortenCoinAlias} {discountPoints}
								</span>
							</S.Total>
						)}
						{discountCash > 0 && (
							<S.Total>
								<span className='bold total'>Desconto aplicado em R$</span>
								<span className='bold total'>
									{discountCash.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							</S.Total>
						)}
						{user?.campaign?.acceptCashPayment && (
							<S.Total>
								<span className='bold total'>Total em R$</span>
								<span className='bold total'>
									{totalApagar.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							</S.Total>
						)}

						{/* <S.Subtext>
          <p>
            * Tarifas adicionais da companhia aérea e do cartão de crédito podem
            ser aplicadas na emissão da passagem. Veja a fatura final
          </p>
        </S.Subtext> */}
						{signed && userPoints < minimumPointsToBuy && (
							<S.AlertStyle>
								<Alert variant='filled' severity='warning'>
									<span className='text'>
										Você deve possuir ao menos <br />
										<span className='value'>
											{minimumPointsToBuy} {coinAlias}
										</span>{' '}
										para realizar esta compra!
									</span>
								</Alert>
							</S.AlertStyle>
						)}
					</S.BlankCardOnly>
					{/* <S.BlankCardOnly>
            <S.Total>
              <span>Valor total</span>
              <span className='bold'>R$ {car.totalAmount}</span>
            </S.Total>
            <Separator className='solid extra-thin' margin={15} />

            <Separator className='solid thin' margin={15} />

            <S.Total>
              <span className='bold'>Total</span>
              <span className='bold'>R$ {car.totalAmount}</span>
            </S.Total>
            <S.Subtext></S.Subtext>
          </S.BlankCardOnly> */}
				</>
			)}
		</div>
	);
};

export default CarSideSummary;
